import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import aboutUs from '../../resources/AboutUs.jpg'
import GurshaanProfilePic from '../../resources/GurshaanProfilePic.jpg'
import RamnikProfilePic from '../../resources/RamnikProfilePic.jpg'
import { ABOUT_US, ABOUT_US_SLIDE_1_DESCRIPTION, GURSHAAN_BIO, GURSHAAN_SINGH, RAMNIK_BIO, RAMNIK_SINGH } from '../util/constants';

function AboutUsCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
        <Carousel className='about-us-carousel-wrapper' activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item className='carousel-item' interval={10000}>
                <div className='about-us-carousel-item-wrapper'>
                    <img className='carousel-picture'
                        src={aboutUs}
                        alt="First slide"
                        />
                    <div >
                        <h1 className='section-title about-us-carousel-title'>{ABOUT_US}</h1>
                        <p className='description-text about-us-carousel-description'>{ABOUT_US_SLIDE_1_DESCRIPTION}</p>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='about-us-carousel-item-wrapper'>
                    <img className='carousel-profile-picture' src={GurshaanProfilePic} alt="Second slide"/>
                    <div >
                        <h1 className='about-us-carousel-title about-us-carousel-name'>{GURSHAAN_SINGH}</h1>
                        <p className='description-text about-us-carousel-description'>{GURSHAAN_BIO}</p>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className='about-us-carousel-item-wrapper'>
                    <img className='carousel-profile-picture' src={RamnikProfilePic} alt="Third slide"/>
                    <div >
                        <h1 className='about-us-carousel-title about-us-carousel-name'>{RAMNIK_SINGH}</h1>
                        <p className='description-text about-us-carousel-description'>{RAMNIK_BIO}</p>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
  );
}

export default AboutUsCarousel;

{/* <Carousel.Item>
<div className='about-us-carousel-item-wrapper'>
    <img
        width="400"
        height="400"
            src="https://cf.geekdo-images.com/slTMH9UhS3KiuNzO0lpQVQ__crop100/img/tZYqJ56SsuwcJAvq5LbwalsXSrE=/100x100/filters:strip_icc()/pic6024534.jpg"
            alt="First slide"
        />
</div>
<Carousel.Caption>
    <h1 className='about-us-carousel-title'>Saajan Singh</h1>
    <p className='about-us-carousel-description'>{tempDescription}</p>
</Carousel.Caption>;
</Carousel.Item> */}