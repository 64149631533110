import React from 'react';
import { CHOREOGRAPHY_DESCRIPTION } from '../util/constants';

function ChoreographyDescription() {
  return (
    <div className='choreography-section-description-wrapper'>
        <h3 className='description-text choreography-section-description'>{CHOREOGRAPHY_DESCRIPTION}</h3>
    </div>
  );
}

export default ChoreographyDescription;
