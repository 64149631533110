import React from 'react';
import FooterTitle from './FooterTitle';
import { CONTACT_INFO_EMAIL, CONTACT_INFO_PHONE } from '../util/constants';

function ContactInformation() {
    const phoneNumber = "(000) 000-0000"
  return (
    <div className="contact-information-wrapper">
        <FooterTitle title="Contact Information" />
        <p className='contact-information-field'>
            Email: <a className='contact-information-field' href= {"mailto: "+CONTACT_INFO_EMAIL}>{CONTACT_INFO_EMAIL}</a> 

        </p>
        <p className='contact-information-field'>
            Phone Number: <a className='contact-information-field' href= {"tel: "+CONTACT_INFO_PHONE}>{CONTACT_INFO_PHONE}</a>

        </p>
    </div>
  );
}

export default ContactInformation;
