import React from 'react';
import HeaderTabs from './HeaderTabs';
import Logo from './Logo';

function Header() {
  return (
    <div className="header" id="header">
      <Logo />
      <HeaderTabs/>     
    </div>
  );
}

export default Header;
