import React from 'react';
import coverPicture from '../../resources/CoverPicture.jpg'
import { COVER_PICTURE_SUBTEXT, COVER_PICTURE_TITLE } from '../util/constants';

function CoverPicture() {
  return (
    <div className="cover-picture-wrapper" id="cover-picture">
      <img className="cover-picture-styling" src={coverPicture}/>
      
      <div className="cover-picture-overlay">
        <div className='cover-picture-labels'>
          <h1 className='cover-picture-title'>{COVER_PICTURE_TITLE}</h1>
          <p className='cover-picture-subtext'>{COVER_PICTURE_SUBTEXT}</p>
        </div>
      </div>
      <div className='cover-picture-bottom-div'/>
    </div>
  );
}

export default CoverPicture;
