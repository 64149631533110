import React from 'react';

function VideoPreview(props) {
    return (
        <div>
            <iframe 
            className='video-preview' 
            width="540" 
            height="300" 
            src={props.video.link}
            title={props.video.title}
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen="allowFullScreen"
            >
            </iframe>
        </div>
    );
}

export default VideoPreview;
