import React from 'react';

function Class(props) {
  return (
    <div className="classes-wrapper">
        <h3 className='classes-wrapper-title-text'>{props.title}</h3>
        <p className='description-text'>{props.description}</p>
    </div>
  );
}

export default Class;
