import React from 'react';

function HeaderTab(props) {
  return (
    <div className="header-tab hover" onClick={props.onClick} id={props.tabInfo.title}>
      <a className='header-tab-label' href={props.tabInfo.url}>{props.tabInfo.title}</a>
    </div>
  );
}

export default HeaderTab;
