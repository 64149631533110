import React from 'react';
import Class from './Class';
import { ADULT_CLASS_DESCRIPTION, ADULT_CLASSES, CLASS_REGISTRATION_BUTTON_TEXT, CLASS_REGISTRATION_LINK, CLASSES_TITLE, YOUTH_CLASS_DESCRIPTION, YOUTH_CLASSES } from '../util/constants';

function ClassesSection() {    
  return (
    <div>
      <div className="classes-section-wrapper">
            <p className='section-title classes-section-open-for-business-label'>{CLASSES_TITLE}</p>
            <div className='classes-section-display-classes-wrapper'>
              <Class title={YOUTH_CLASSES} description={YOUTH_CLASS_DESCRIPTION}/>
              <Class title={ADULT_CLASSES} description={ADULT_CLASS_DESCRIPTION}/>
            </div>
            <a className='description-text classes-button' href={CLASS_REGISTRATION_LINK} target="_blank">
              {CLASS_REGISTRATION_BUTTON_TEXT}
            </a>
      </div>
      <div className='classes-section-bottom-div'/>
    </div>
  );
}

export default ClassesSection;
