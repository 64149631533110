import React from 'react';
import { useState } from "react";
import emailjs from '@emailjs/browser';
import { CHOREOGRAPHY_DESCRIPTION_PLACEHOLDER, CHOREOGRAPHY_EMAIL_PLACEHOLDER, CHOREOGRAPHY_NAME_PLACEHOLDER, ERROR_SERVER_MESSAGE, ERROR_VALIDATION_MESSAGE, SUCCESS_MESSAGE } from '../util/constants';
import { Spinner } from 'react-bootstrap';

function ChoreographyForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [errorFields, setErrorFields] = useState([]);
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [loadingCall, setLoadingCall] = useState(false)

    const NAME_ERROR = "NAME_ERROR"
    const EMAIL_ERROR = "EMAIL_ERROR"
    const DESCRIPTION_ERROR = "DESCRIPTION_ERROR"


    function changeName(event) {
        setName(event.target.value);
    }

    function changeEmail(event) {
        setEmail(event.target.value);
    }

    function changeDescription(event) {
        setDescription(event.target.value);
    }

    function addSuccessMessage() {
        setSuccessMessage(SUCCESS_MESSAGE);
    }

    function clearValues() {
        setName("");
        setEmail("");
        setDescription("");
    }

    function validateFields() {
        setErrorFields([]);

        var invalidName = /^[\S ]+$/.test(name) == false
        var invalidDescription = /^[\S ]+$/.test(description) == false
        var invalidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email) == false

        if(invalidName){
            setErrorFields(errorFields => [...errorFields, NAME_ERROR])
        }

        if(invalidEmail) {
            setErrorFields(errorFields => [...errorFields, EMAIL_ERROR])
        }

        if(invalidDescription) {
            setErrorFields(errorFields => [...errorFields, DESCRIPTION_ERROR])
        }

        if(invalidName || invalidEmail || invalidDescription) {
            setErrorMessage(ERROR_VALIDATION_MESSAGE)
        } else {
            setErrorMessage("")
        }
    }
        

    function submitForm() {
        setLoadingCall(true);
        setSuccessMessage("");
        validateFields();
        
        if (errorFields.length < 1) {
            var templateParams = {
                name: name,
                email: email,
                description: description,
            };

            emailjs.send('service_67lendq', 'template_hswipbk', templateParams, {
                publicKey: '-nyA-a0_7RswW4RzN',
            })
            .then(
                () => {
                    clearValues();
                    addSuccessMessage();
                    setLoadingCall(false);
                },
                (error) => {
                    console.log('Failed call', error);
                    setLoadingCall(false);
                    setErrorMessage(ERROR_SERVER_MESSAGE)
                },
          );
        }
    }

    console.log("error fields: " + JSON.stringify(errorFields))
  return (
        <div className='choreography-section-form'>
            {
                (errorFields.indexOf(NAME_ERROR) > -1) ? 
                <input className='choreography-section-input-validation-error' value={name} onChange={changeName} pattern="[a-zA-Z]" placeholder={CHOREOGRAPHY_NAME_PLACEHOLDER} required/> 
                :
                <input className='choreography-section-input' value={name} onChange={changeName} pattern="[a-zA-Z]" placeholder={CHOREOGRAPHY_NAME_PLACEHOLDER} required/>
            }
            {
                (errorFields.indexOf(EMAIL_ERROR) > -1) ? 
                <input className='choreography-section-input-validation-error' value={email} onChange={changeEmail} placeholder={CHOREOGRAPHY_EMAIL_PLACEHOLDER} required/> 
                :
                <input className='choreography-section-input' value={email} onChange={changeEmail} placeholder={CHOREOGRAPHY_EMAIL_PLACEHOLDER} required/>
            }
            {
                (errorFields.indexOf(DESCRIPTION_ERROR) > -1) ? 
                <textarea className='choreography-section-input-validation-error choreography-section-input-description' onChange={changeDescription} value={description} placeholder={CHOREOGRAPHY_DESCRIPTION_PLACEHOLDER} required/> 
                :
                <textarea className='choreography-section-input choreography-section-input-description' onChange={changeDescription} value={description} placeholder={CHOREOGRAPHY_DESCRIPTION_PLACEHOLDER} required/>
            }
            {
                (errorMessage.length > 0) ? <p className='choreography-section-input choreography-section-input-error'>{errorMessage}</p>  : <></>
            }
            {
                (successMessage.length > 0) ? <p className='choreography-section-input choreography-section-input-success'>{successMessage}</p>  : <></>
            }
            {
                (loadingCall) ? <Spinner className='choreography-section-spinner'/> : <button type='submit' className='choreography-section-button' onClick={submitForm}>Submit</button>
            }
            
        </div>
  );
}

export default ChoreographyForm;
