import React, { useEffect, useRef, useState } from 'react';
import HeaderTab from './HeaderTab';
import { HEADER_TABS } from '../util/constants';

function HeaderTabs() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Reference to the menu container

  const handleClickOutside = (event) => {
    console.log("clicked mouse")
      // Close the menu if the click is outside the menu
      if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
      }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickedMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <input type="checkbox" checked={isOpen} id="toggle-menu"></input>
      <label for="toggle-menu" class="hamburger" onClick={handleClickedMenu}>
        &#9776;
      </label>
      <div className="menu" id="menu" ref={menuRef}>
          {
              HEADER_TABS.map(headerTab => <HeaderTab onClick={handleClickedMenu} tabInfo={headerTab}/>)
          }
      </div>
    </div>
  );
}

export default HeaderTabs;
