import React from 'react';
import './App.css';
import './components.css';
import Header from './Components/Header/Header';
import CoverPicture from './Components/CoverPicture/CoverPicture';
import ClassesSection from './Components/Classes/ClassesSection';
import PreviousPerformancesSection from './Components/PreviousPerformances/PreviousPerformancesSection';
import AboutUsSection from './Components/AboutUs/AboutUsSection';
import ChoreographySection from './Components/Choreography/ChoreographySection';
import Footer from './Components/Footer/Footer';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';


function App() {
  ReactGA.initialize('G-CS9BETENVV');
  
  useEffect(() => {
    ReactGA.send ({
      hitType: "pageview",
      page: "/",
      title: "Home"
    });
  }, []);
  
  return (
    <div className="App">
       <Header/>
       <CoverPicture/>
       <a id='classes'> </a>
       <ClassesSection/>
       <a id='performances'/>
       <PreviousPerformancesSection/>
       <a id='about-us' />
       <AboutUsSection/>
       <a id='choreography' />
       <ChoreographySection/>
       <a id='contact-us-socials' />
       <Footer/>
    </div>
  );
}

export default App;
