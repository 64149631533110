import React from 'react';
import logo from '../../resources/Logo.jpg'

function Logo() {
  return (
    <div className="logo hover" id="logo">
      <a href='/'>
        <img className='logo-image' src={logo}></img>
      </a>
    </div>
  );
}

export default Logo;
