import React from 'react';
import ContactInformation from './ContactInformation';
import Socials from './Socials';

function Footer() {
  return (
    <div className="footer">
      <ContactInformation />
      <Socials/>     
    </div>
  );
}

export default Footer;
