import React from 'react';
import AboutUsCarousel from './AboutUsCarousel';

function AboutUsSection() {
  return (
    <div className='about-us-section-wrapper'>
        <div className='about-us-top-border'>
            <div className='about-us-top-border-circle'/>
            <div className='about-us-top-border-circle'/>
            <div className='about-us-top-border-circle'/>
            <div className='about-us-top-border-circle'/>
        </div>
        <AboutUsCarousel/>
    </div>
  );
}

export default AboutUsSection;
