import React from 'react';
import ChoreographyForm from './ChoreographyForm';
import ChoreographyDescription from './ChoreographyDescription';
import { CHOREOGRAPHY_TITLE } from '../util/constants';

function ChoreographySection() {
    const descriptionPlaceholder = "Description of what choreography you would like to have done for (duration, " +
    "number of songs, what event this is for, etc)"
  return (
    <div className='choreography-section-wrapper'>
        <h1 className='section-title choreography-section-title'>{CHOREOGRAPHY_TITLE}</h1>
        <div className='choreography-section-split-content'>
          <ChoreographyDescription />
          <ChoreographyForm />
        </div>
    </div>
  );
}

export default ChoreographySection;