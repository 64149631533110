import React from 'react';
import VideoPreview from './VideoPreview';
import { PREVIOUS_PERFORMANCES_TITLE, YOUTUBE_VIDEO_LINKS } from '../util/constants';

function PreviousPerformancesSection() {
    return (
        <div className="performances-section-wrapper" id='Performances'>
            <h1 className='section-title performances-section-title'>{PREVIOUS_PERFORMANCES_TITLE}</h1>
            <div className='performances-section-videos-wrapper'>
                {
                    YOUTUBE_VIDEO_LINKS.map(video => <VideoPreview video={video} />)
                }
            </div>
        </div>
    );
}

export default PreviousPerformancesSection;